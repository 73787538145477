<template>
  <div>
  
    <div class="order-top-grey-line py-2 ">
      <div class="container">
          <div  class="order-top-step-line step-main">
              <div type="button" :class="step == 1 ? 'order-step-active' : ''"   @click="step = 1" class="order-step order-step-1">
                  <div :class="step == 1 ? 'number-circle-active' : ''" class="number-circle ">1</div>
                  <span :class="step == 1 ? 'text-green ' : ''" class="text">Order details</span>
                  <div class="number-circle-mob">1/3</div>
              </div>
              <div class="shot-line-grey"></div>
              <div type="button"   @click="nextStep(2)" :class="step == 2 ? 'order-step-active' : ''"    class="order-step order-step-2" >
                  <div :class="step == 2 ? 'number-circle-active' : ''" class="number-circle">2</div>
                  <a class="order-back-mob" href="#back" ><img src="files/images/iconly/back-arrow.svg"></a>
                  <span :class="step == 2 ? 'text-green ' : ''" class="text">Order Preferences</span>
                  <div class="number-circle-mob">2/3</div>
              </div>
              <div class="shot-line-grey"></div>
              <div type="button"   @click="nextStep(3)" :class="step == 3 ? 'order-step-active' : ''"   class="order-step order-step-3" >
                  <div :class="step == 3 ? 'number-circle-active' : ''" class="number-circle">3</div>
                  <span :class="step == 3 ? 'text-green ' : ''" class="text"><a class="order-back-mob" href="#back"  ><img src="files/images/iconly/back-arrow.svg"></a>  Account Details</span>
                  <div class="number-circle-mob">3/3</div>
              </div>
          </div>
      </div>
    </div>
    <div class="container  py-2">
     
      <div  class=" scroll-container " ref="scrollContainer">
        <div  class="rc-radios rc-radios--theme-default vertical-on-small ServiceTypeControl  "  data-testid="serviceTypeControl">
          <div v-for="formType in orderFormTypes" :key="formType" class="radio-button-wrapper radio-button-wrapper--flexbuttons radio-button-wrapper--same-width">
            <button @click="changeOrderTypeForm(formType)"  type="button" class="radio-button radio-button--n-37 " :class="orderForm_type == formType.desc ? 'active' : '' "  tabindex="-1" data-testid="radio-button">
              <div class="button-checkbox"></div>
              <div class="ServiceTypeControl-button ServiceTypeControl-button--academic-writing">
                <div class="ServiceTypeControl-button__title">{{formType.title}}</div>
                <div class="ServiceTypeControl-button__subTitle">{{formType.desc}}</div>
              </div>
            </button>
          </div>
        </div>
      
      </div>


    </div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>
    <div v-else class="layout1">
      <div class="container my-1">
        <div class="row">
          <div class="col-12 col-sm-8 section-left mb-3">
            <!-- nav tabs -->
            
                       
             <!-- <ul
              class="
                nav nav-pills
                mb-3
                nav-justified
                border-1 border-primary
                rounded
                shadow
              "
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link rounded font-weight-bold cursor-pointer"
                  :class="step == 1 ? 'active' : ''"
                  id="paper-details-tab"
                  :aria-selected="step == 1 ? true : false"
                  @click="step = 1"
                  >1. Paper Details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link rounded font-weight-bold cursor-pointer"
                  :class="step == 2 ? 'active' : ''"
                  id="order-preferences-tab"
                  :aria-selected="step == 2 ? true : false"
                  @click="nextStep(2)"
                  >2. Order Preferences</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link rounded font-weight-bold cursor-pointer"
                  :class="step == 3 ? 'active' : ''"
                  id="account-tab"
                  :aria-selected="step == 3 ? true : false"
                  @click="nextStep(3)"
                  >3. Account Details</a
                >
              </li>
            </ul>  -->
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade"
                :class="step == 1 ? 'show active' : ''"
                id="paper-details"
                role="tabpanel"
                aria-labelledby="paper-details-tab"
              >


                <div v-if="orderForm_type == 'Custom Papers'" class="box--shadow bg-white rounded-custom p-3 p-sm-4">

                  
                  <!-- step1 -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Type of Document</label
                    >
                    <div class="col-sm-9">
                      <v-select
                        label="desc"
                        v-model="orderForm.document_type"
                        placeholder="Start typing to search"
                        @input="calculatePrice"
                        :options="document_types"
                        :reduce="(doc) => doc.id"
                      ></v-select>
                    </div>
                  </div>
                  <!-- ends type of document  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Subject Area</label
                    >
                    <div class="col-sm-9">
                      <v-select
                        label="desc"
                        v-model="orderForm.subject_area"
                        placeholder="Start typing to search"
                        @input="calculatePrice"
                        :options="subject_areas"
                        :reduce="(area) => area.id"
                      ></v-select>
                    </div>
                  </div>
                  <!-- ends subject area -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Academic Level</label
                    >
                    <div class="col-sm-9 text-left">
                      <div
                        class="btn-group overflow-auto pb-2"
                        v-if="academic_levels.length > 0"
                      >
                        <button
                          type="button"
                          class="btn"
                          v-for="(level, index) in academic_levels"
                          :key="index + 'al'"
                          :class="
                            orderForm.academic_level === level.id
                              ? 'btn--primary'
                              : 'border-1 border-muted'
                          "
                          @click="activateAcademicLevel(level)"
                        >
                          {{ level.desc }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- ends academic leve -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Title <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.title"
                          class="form-control text-left"
                          :class="!!errors.title ? 'invalid' : ''"
                          required
                        />
                        <BasePopover>
                          Should not be less than 7 characters. (A unique
                          identifier for easy order tracking. E.g Assignment
                          1034)
                        </BasePopover>
                      </div>
                      <p
                        class="text-danger text-left my-1"
                        v-if="'title' in errors"
                      >
                        {{ errors.title }}
                      </p>
                    </div>
                  </div>
                  <!-- ends title -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Order Instructions
                      <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      <!-- <vue-editor
                        :class="!!errors.description ? 'invalid' : ''"
                        v-model="orderForm.description"
                      ></vue-editor> -->
                      <div class="d-flex flex-row align-items-baseline">
                        <!-- <textarea
                          :class="!!errors.description ? 'invalid' : ''"
                          rows="4"
                          v-model="orderForm.description"
                          class="form-control"
                          id="inputEmail3"
                          placeholder="Paste your order/paper/assignment instructions"
                        ></textarea> -->
                        <!-- <vue-editor 
                        :class="!!errors.description ? 'invalid' : ''"
                        v-model="orderForm.description"
                      ></vue-editor> -->
                      <div class="d-flex flex-row custom_editor">
                          <ckeditor v-model="orderForm.description"></ckeditor>

                        </div>
                        <BasePopover>
                          Your personal information (Phone Number, Email
                          Address) is always kept private and we won't share
                          it–just don't include it in your order details.
                        </BasePopover>
                      </div>
                      <p
                        class="text-danger text-left my-1"
                        v-if="'title' in errors"
                      >
                        {{ errors.title }}
                      </p>
                      <!-- <p class="mb-1 text-warning text-left">
                        <span class="icon-help"></span> Your personal
                        information (Phone Number, Email Address) is always kept
                        private and we won't share it–just don't include it in
                        your order details.
                      </p> -->
                    </div>
                  </div>
                  <!-- ends description -->

                  <!-- ends title -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Order Files</label
                    >
                    <div class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                    </div>
                    
                  </div>

                  <!-- ends file upload -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >State</label
                    >
                    <div class="col-sm-9 d-flex flex-row align-items-baseline">
                      <input
                        type="text"
                        v-model="orderForm.state"
                        class="form-control"
                        id="inputEmail3"
                      />
                      <BasePopover>Applies to US citizens</BasePopover>
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Nursing Speciality</label
                    >
                    <div class="col-sm-9 d-flex flex-row align-items-baseline">
                      <input
                        type="text"
                        v-model="orderForm.speciality"
                        class="form-control"
                        id="inputEmail3"
                      />
                      <BasePopover>Applies to nursing writing</BasePopover>
                    </div>
                  </div>
                  <!-- ends nursing speciality and state -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Paper format/Style</label
                    >
                    <div class="col-sm-9 text-left">
                      <div
                        class="btn-group overflow-auto"
                        v-if="styles.length > 0"
                      >
                        <button
                          type="button"
                          class="btn"
                          v-for="(style, index) in styles"
                          :key="index + 'al'"
                          :class="
                            orderForm.style === style.id
                              ? 'btn--primary'
                              : 'border-1 border-muted'
                          "
                          @click="activateAcademicStyle(style)"
                        >
                          {{ style.desc }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- ends paper format type -->
                  <div class="form-group row">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Language Style</label
                    >
                    <div class="col-sm-9 text-left">
                      <div
                        class="btn-group overflow-auto"
                        v-if="language_styles.length > 0"
                      >
                        <button
                          type="button"
                          class="btn"
                          v-for="(style, index) in language_styles"
                          :key="index"
                          :class="
                            orderForm.language_style === style.id
                              ? 'btn--primary'
                              : 'border-1 border-muted'
                          "
                          @click="activateLanguageStyle(style)"
                        >
                          {{ style.desc }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- ends language style -->

                  <div class="form-group row">
                    <label
                      for="inputEmail3"
                      class="col-sm-3 col-form-label text-right"
                      >Deadline / Urgency</label
                    >
                    <div class="col-sm-9">
                      <v-select
                        label="desc"
                        v-model="orderForm.urgency"
                        placeholder="Start typing to search"
                        @input="calculatePrice"
                        :options="urgency"
                        :reduce="(urg) => urg.id"
                      ></v-select>
                    </div>
                  </div>
                  <!-- ends urgency  -->
                  <div class="is-flex">
                    <div class="form-group row">
                      <label
                        class="
                          col-form-label
                          py-0
                          col-3
                          pt-0
                          text-left text-sm-right
                        "
                        >No of Pages/Words</label
                      >
                      <div class="col-9 d-flex flex-row align-items-baseline">
                        <div>
                          <div class="input-group">
                            <span class="input-group-prepend">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="updatePages(0)"
                              >
                                <i class="icon-minus3"></i>
                              </button>
                            </span>
                            <input
                              type="number"
                              @change="calculatePrice"
                              v-model="orderForm.pages"
                              name="pages"
                              style="min-width: 72px"
                              min="0"
                              :max="number_of_pages.max_pages"
                              class="form-control text-center"
                              placeholder
                            />
                            <span class="input-group-append">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="updatePages(1)"
                              >
                                <i class="icon-plus3"></i>
                              </button>
                            </span>
                          </div>
                          <strong class="mt-1"
                            >{{ no_of_words || "275" }} words</strong
                          >
                        </div>
                        <!-- help tooltip -->
                        <BasePopover>
                          Please choose how many pages you need. 1 double spaced
                          page is approximately 275 words, 1 single spaced page
                          is approximately 550 words. Title and bibliography
                          pages are included for free.
                        </BasePopover>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        class="
                          col-form-label
                          py-0
                          col-3
                          pt-0
                          text-left text-sm-right
                        "
                        >Spacing</label
                      >
                      <div class="col-9 text-left">
                        <div class="btn-group" v-if="spacing.length > 0">
                          <button
                            type="button"
                            class="btn"
                            v-for="(space, index) in spacing"
                            :key="index + 'sp'"
                            :class="
                              orderForm.spacing === space.id
                                ? 'btn--primary'
                                : 'border-1 border-muted'
                            "
                            @click="activateSpacing(space)"
                          >
                            {{ space.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ends pages -->
                  <div class="row">
                    <div class="col-12 col-md-3 offset-md-3">
                      <div class="form-group row">
                        <label
                          class="
                            col-form-label
                            py-0
                            col-3 col-sm-12
                            pt-0
                            text-left
                          "
                          >Charts:</label
                        >
                        <div class="col-8 col-sm-12">
                          <div class="input-group">
                            <span class="input-group-prepend">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="updateCharts(0)"
                              >
                                <i class="icon-minus3"></i>
                              </button>
                            </span>
                            <input
                              @change="calculatePrice"
                              type="number"
                              v-model="orderForm.charts"
                              name="charts"
                              min="1"
                              class="form-control text-center"
                              placeholder
                            />
                            <span class="input-group-append">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="updateCharts(1)"
                              >
                                <i class="icon-plus3"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="form-group row">
                        <label
                          class="
                            col-form-label
                            py-0
                            col-3 col-sm-12
                            pt-0
                            text-left
                          "
                          >PowerPoint slides:</label
                        >
                        <div class="col-8 col-sm-12">
                          <div class="input-group">
                            <span class="input-group-prepend">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="updateSlides(0)"
                              >
                                <i class="icon-minus3"></i>
                              </button>
                            </span>
                            <input
                              @change="calculatePrice"
                              type="number"
                              v-model="orderForm.slides"
                              name="slides"
                              min="1"
                              class="form-control text-center"
                              placeholder
                            />
                            <span class="input-group-append">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="updateSlides(1)"
                              >
                                <i class="icon-plus3"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ends slides and presentations -->
                    <div class="col-12 col-md-3">
                      <div class="form-group row">
                        <label
                          class="
                            col-form-label
                            py-0
                            col-3 col-sm-12
                            pt-0
                            text-left
                          "
                          >Sources to be cited:</label
                        >
                        <div class="col-8 col-sm-12">
                          <div class="input-group">
                            <span class="input-group-prepend">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="decrementSources"
                              >
                                <i class="icon-minus3"></i>
                              </button>
                            </span>
                            <input
                              type="number"
                              v-model="orderForm.no_sources"
                              name="no_sources"
                              min="0"
                              class="form-control text-center"
                              placeholder
                            />
                            <span class="input-group-append">
                              <button
                                class="btn btn-outline-green btn-icon"
                                type="button"
                                @click="orderForm.no_sources++"
                              >
                                <i class="icon-plus3"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ends pages slides and PowerPoint -->
                  <!-- totals and next -->
                  <div class="form-group row">
                    <!-- <label
                      for="inputEmail3"
                      class="
                        col-2 col-sm-3 col-form-label py-0
                        text-left text-sm-right
                        font-weight-bold
                      "
                      >Total price:</label
                    >
                    <div class="col-5 col-sm-3">
                      <h1 class="font-weight-bold text-left">${{ "0.0" }}</h1>
                    </div> -->
                    <div class="col-12 col-sm-6 text-left offset-sm-3">
                      <button
                        class="
                          btn btn--custom btn-block
                          btn-lg
                          px-5
                          py-2
                          font-weight-bold
                          nav-link
                        "
                        @click="nextStep(2)"
                      >
                        NEXT STEP
                      </button>
                    </div>
                  </div>
                  <!-- ends totals and next -->
                  <!-- ./step1 -->
                </div>

                <div v-if="orderForm_type == 'Custom iHuman'" class="box--shadow bg-white rounded-custom p-3 p-sm-4">
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Case Study Title  <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.title"
                          class="form-control text-left"
                          :class="!!errors.title ? 'invalid' : ''"
                          placeholder="Case Study Title"
                          required
                        />
                        <BasePopover>
                          Give name of the case study ( eg Tina Jones Health History)
                        </BasePopover>
                      </div>
                      <p
                        class="text-danger text-left my-1"
                        v-if="'title' in errors"
                      >
                        {{ errors.title }}
                      </p>
                    </div>
                  </div>
                  <!-- ends title -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Delivery Time</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <select
                          @change="calculatePrice"
                          v-model="orderForm.special_urgency"
                          name="select-size-9"
                          id="select-size-9"
                          class="border_gray_200 form-control form-control-md"
                        >
                          <option
                            value="3"
                          >
                            3 Days
                          </option>
                          <option
                            value="5"
                          >
                            5 Days
                          </option>
                          <option
                            value="10"
                          >
                            10 Days
                          </option>
                        </select>
                      
                    </div>
                  </div>
                  <!-- ends Delivery Time  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Academic Level</label
                    >
                    <div class="col-sm-9 text-left">
                      <div
                        class="btn-group overflow-auto pb-2"
                        v-if="academic_levels.length > 0"
                      >
                        <button
                          type="button"
                          class="btn"
                          v-for="(level, index) in academic_levels"
                          :key="index + 'al'"
                          :class="
                            orderForm.academic_level === level.id
                              ? 'btn--primary'
                              : 'border-1 border-muted'
                          "
                          @click="activateAcademicLevel(level)"
                        >
                          {{ level.desc }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- ends academic leve -->

                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Order Files</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                    </div>
                    
                  </div>
                  <!-- ends file upload -->

                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Additional Comments
                      <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      
                      <div class="d-flex flex-row align-items-baseline">
                      
                      <div class="d-flex flex-row custom_editor">
                          <ckeditor v-model="orderForm.description_extras.comments"></ckeditor>

                        </div>
                        <BasePopover>
                          Your personal information (Phone Number, Email
                          Address) is always kept private and we won't share
                          it–just don't include it in your order details.
                        </BasePopover>
                      </div>
                      
                      
                    </div>
                  </div>
                  <!-- ends description -->

              
                </div>

                <div v-if="orderForm_type == 'Custom Quiz'" class="box--shadow bg-white rounded-custom p-3 p-sm-4">
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Course code  </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.course_code"
                          class="form-control text-left"
                          placeholder="Course Code"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends Course Code -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Course name </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.course_name"
                          class="form-control text-left"
                          placeholder="Course name"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends Course name -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >University  </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.university"
                          class="form-control text-left"
                          placeholder="University"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends University -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Academic Level</label
                    >
                    <div class="col-sm-9 text-left">
                      <div
                        class="btn-group overflow-auto pb-2"
                        v-if="academic_levels.length > 0"
                      >
                        <button
                          type="button"
                          class="btn"
                          v-for="(level, index) in academic_levels"
                          :key="index + 'al'"
                          :class="
                            orderForm.academic_level === level.id
                              ? 'btn--primary'
                              : 'border-1 border-muted'
                          "
                          @click="activateAcademicLevel(level)"
                        >
                          {{ level.desc }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- ends academic leve -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Delivery Time</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <select
                          @change="calculatePrice"
                          v-model="orderForm.special_urgency"
                          name="select-size-9"
                          id="select-size-9"
                          class="border_gray_200 form-control form-control-md"
                        >
                          <option
                            value="3"
                          >
                            3 Days
                          </option>
                          <option
                            value="5"
                          >
                            5 Days
                          </option>
                          <option
                            value="10"
                          >
                            10 Days
                          </option>
                        </select>
                      
                    </div>
                  </div>
                  <!-- ends Delivery Time  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Order Files</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                    </div>
                    
                  </div>
                  <!-- ends file upload -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Email or Username   </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.school_email_username"
                          class="form-control text-left"
                          placeholder="Email/username "
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends Email/username  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Password   </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.school_password"
                          class="form-control text-left"
                          placeholder="Password"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends school_password  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Additional Comments
                      <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      
                      <div class="d-flex flex-row align-items-baseline">
                      
                      <div class="d-flex flex-row custom_editor">
                          <ckeditor v-model="orderForm.description_extras.comments"></ckeditor>

                        </div>
                        <BasePopover>
                          Your personal information (Phone Number, Email
                          Address) is always kept private and we won't share
                          it–just don't include it in your order details.
                        </BasePopover>
                      </div>
                      
                      
                    </div>
                  </div>
                  <!-- ends description -->
                  
                  


                  


              
                </div>

                <div v-if="orderForm_type == 'Custom Exams'" class="box--shadow bg-white rounded-custom p-3 p-sm-4">
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Type of Exams</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <select
                          v-model="orderForm.description_extras.type"
                          name="select-size-9"
                          id="select-size-9"
                          class="border_gray_200 form-control form-control-md"
                        >
                        <option
                          value="Midterm exam"
                        >
                        Midterm exam
                        </option>
                        <option
                          value="Final exam"
                        >
                        Final exam
                        </option>
                        </select>
                      
                    </div>
                  </div>
                  <!-- ends Type of Exams  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Course code  </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.course_code"
                          class="form-control text-left"
                          placeholder="Course Code"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends Course Code -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Course name </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.course_name"
                          class="form-control text-left"
                          placeholder="Course name"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends Course name -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >University  </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.university"
                          class="form-control text-left"
                          placeholder="University"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends University -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Academic Level</label
                    >
                    <div class="col-sm-9 text-left">
                      <div
                        class="btn-group overflow-auto pb-2"
                        v-if="academic_levels.length > 0"
                      >
                        <button
                          type="button"
                          class="btn"
                          v-for="(level, index) in academic_levels"
                          :key="index + 'al'"
                          :class="
                            orderForm.academic_level === level.id
                              ? 'btn--primary'
                              : 'border-1 border-muted'
                          "
                          @click="activateAcademicLevel(level)"
                        >
                          {{ level.desc }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- ends academic leve -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Delivery Time</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <select
                          @change="calculatePrice"
                          v-model="orderForm.special_urgency"
                          name="select-size-9"
                          id="select-size-9"
                          class="border_gray_200 form-control form-control-md"
                        >
                          <option
                            value="3"
                          >
                            3 Days
                          </option>
                          <option
                            value="5"
                          >
                            5 Days
                          </option>
                          <option
                            value="10"
                          >
                            10 Days
                          </option>
                        </select>
                      
                    </div>
                  </div>
                  <!-- ends Delivery Time  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Order Files</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                    </div>
                    
                  </div>
                  <!-- ends file upload -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Email or Username   </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.school_email_username"
                          class="form-control text-left"
                          placeholder="Email/username "
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends Email/username  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Password   </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.school_password"
                          class="form-control text-left"
                          placeholder="Password"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends school_password  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Additional Comments
                      <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      
                      <div class="d-flex flex-row align-items-baseline">
                      
                      <div class="d-flex flex-row custom_editor">
                          <ckeditor v-model="orderForm.description_extras.comments"></ckeditor>

                        </div>
                        <BasePopover>
                          Your personal information (Phone Number, Email
                          Address) is always kept private and we won't share
                          it–just don't include it in your order details.
                        </BasePopover>
                      </div>
                      
                      
                    </div>
                  </div>
                  <!-- ends description -->
                
                </div>

                <div v-if="orderForm_type == 'Shadow Health'" class="box--shadow bg-white rounded-custom p-3 p-sm-4">
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      ></label>
                    <div class="col-sm-9">
                      <div v-if="orderForm_type == 'Shadow Health' " class=" scroll-container mt-2 " ref="scrollContainer">
                        <div
                        style="align-items: flex-start; justify-content: flex-start;"
                          class="rc-radios rc-radios--theme-default vertical-on-small ServiceTypeControl  "  data-testid="serviceTypeControl">
                          
                            <div
                              class="
                                btn-group
                                atbd-button-group
                                btn-group-normal-custom
                                my-2
                                overflow-auto
                              "
                              role="group"
                            >
                            <!-- {{ orderForm.academic_level }} -->
                              <button
                                style="min-width: 240px !important; line-height:1.3em!important; "
                                v-for="(shadow, index) in ShadowHealthTypes"
                                :key="index + 'om'"
                                type="button"
                                class="btn col-3 py-2 px-1 d-flex align-items-center justify-content-center btn-sm btn-outline-light-custom"
                                :class="
                                  orderForm.type == shadow.type
                                    ? 'active'
                                    : ''
                                "
                                @click="activateShadowHealth(shadow)"
                              >
                                {{ shadow.desc }}
                              </button>
                            </div>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ends shadow health type -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Case Study Title  <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.title"
                          class="form-control text-left"
                          :class="!!errors.title ? 'invalid' : ''"
                          placeholder="Case Study Title"
                          required
                        />
                        <BasePopover>
                          Give name of the case study ( eg Tina Jones Health History)
                        </BasePopover>
                      </div>
                      <p
                        class="text-danger text-left my-1"
                        v-if="'title' in errors"
                      >
                        {{ errors.title }}
                      </p>
                    </div>
                  </div>
                  <!-- ends title -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Academic Level</label
                    >
                    <div class="col-sm-9 text-left">
                      <div
                        class="btn-group overflow-auto pb-2"
                        v-if="academic_levels.length > 0"
                      >
                        <button
                          type="button"
                          class="btn"
                          v-for="(level, index) in academic_levels"
                          :key="index + 'al'"
                          :class="
                            orderForm.academic_level === level.id
                              ? 'btn--primary'
                              : 'border-1 border-muted'
                          "
                          @click="activateAcademicLevel(level)"
                        >
                          {{ level.desc }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- ends academic leve -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Delivery Time</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <select
                          @change="calculatePrice"
                          v-model="orderForm.special_urgency"
                          name="select-size-9"
                          id="select-size-9"
                          class="border_gray_200 form-control form-control-md"
                        >
                          <option
                            value="3"
                          >
                            3 Days
                          </option>
                          <option
                            value="5"
                          >
                            5 Days
                          </option>
                          <option
                            value="10"
                          >
                            10 Days
                          </option>
                        </select>
                      
                    </div>
                  </div>
                  <!-- ends Delivery Time  -->
                  <div v-if="orderForm.type != 'shadow' " class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >upload template</label
                    >
                    <div style="max-width: 485px;" class="col-sm-9 text-left">
                        <FileUploader v-model="orderForm.files"></FileUploader>
                    </div>
                    
                  </div>
                  <!-- ends file upload -->

                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Email or Username   </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.school_email_username"
                          class="form-control text-left"
                          placeholder="Email/username "
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends Email/username  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Password   </label
                    >
                    <div style="max-width: 485px;" class="col-sm-9">
                      <div class="d-flex flex-row align-items-baseline">
                        <input
                          type="text"
                          v-model="orderForm.description_extras.school_password"
                          class="form-control text-left"
                          placeholder="Password"
                          required
                        />
                      </div>
                      
                    </div>
                  </div>
                  <!-- ends school_password  -->
                  <div class="form-group row mb-2">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Additional Comments
                      <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                      
                      <div class="d-flex flex-row align-items-baseline">
                      
                      <div class="d-flex flex-row custom_editor">
                          <ckeditor v-model="orderForm.description_extras.comments"></ckeditor>

                        </div>
                        <BasePopover>
                          Your personal information (Phone Number, Email
                          Address) is always kept private and we won't share
                          it–just don't include it in your order details.
                        </BasePopover>
                      </div>
                      
                      
                    </div>
                  </div>
                  <!-- ends description -->
                
                </div>



              </div>


              <!-- ends step 1 -->
              <div
                class="tab-pane fade"
                :class="step == 2 ? 'show active' : ''"
                id="order-preferences"
                role="tabpanel"
                aria-labelledby="order-preferences-tab"
              >
                <div class="box--shadow bg-white rounded-custom p-2 p-sm-3 mb-3">
                  <div class="form-group row">
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Writer preferences</label
                    >
                    <div class="col-sm-9">
                      <div
                        class="preferences row"
                        v-if="writer_levels.length > 0"
                      >
                        <div
                          class="col-12 col-sm-4"
                          v-for="(pref, index) in writer_levels"
                          :key="index + 'wp'"
                        >
                          <button
                            type="button"
                            class="btn preference border-1"
                            :class="
                              orderForm.writer_level === pref.id
                                ? 'selected border--green'
                                : 'default border-muted'
                            "
                            @click="activateWriterPref(pref)"
                          >
                            <div class="d-flex flex-column align-items-start justify-content-center" style="vertical-align: top !important">
                              <div
                                v-show="orderForm.writer_level === pref.id"
                                class="
                                  badge badge--green
                                  rounded-circle
                                  selected
                                  
                                "
                                style="padding: 6px 6px"
                              >
                                <span class="icon-check"></span>
                              </div>
                              <p class="my-1 pref-title font-weight-bold">
                                {{ pref.title }}
                                <br />
                              </p>
                              <p
                                class="my-2 text-left  text-capitalize"
                                style="
                                  white-space: normal;
                                  word-wrap: break-word;
                                "
                              >
                                {{ pref.desc }}
                              </p>
                              <h4 class="text-success font-weight-bold mb-1">
                                {{ `${pref.cost}` || "" }}
                              </h4>
                              
                              
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ends preferences -->
                  <div v-if="additional_services.length > 0">
                    <div
                      class="form-group row mb-1"
                      v-for="(service, index) in additional_services"
                      :key="index"
                    >
                      <label
                        for="inputEmail3"
                        class="
                          col-4 col-sm-3 col-form-label
                          py-0
                          pt-2
                          text-right
                        "
                        >{{ service.title }}</label
                      >
                      <div class="col-8 col-sm-9 pb-0 mb-0">
                        <div class="additional-service text-left">
                          <div class="custom-control custom-checkbox mb-0">
                            <input
                              v-model="orderForm.additional_services"
                              @change="calculatePrice"
                              type="checkbox"
                              class="custom-control-input border-1"
                              :id="service.id"
                              :value="service.id"
                            />
                            <label
                              class="custom-control-label d-flex flex-row"
                              :for="service.id"
                            >
                              <h5
                                class="my-0 text-success font-weight-bold my-0"
                              >
                                + ${{ service.cost || 0 }}
                              </h5>
                              <BasePopover>{{ service.desc }}</BasePopover>
                            </label>
                          </div>
                          <!-- <p class="mt-0 mb-1"></p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ends additional_services -->
                  <div
                    class="form-group row prefered-writer"
                    v-if="isAuthenticated && Object.keys(authUser).length > 0"
                  >
                    <label
                      for="inputEmail3"
                      class="
                        col-sm-3 col-form-label
                        py-0
                        text-left text-sm-right
                      "
                      >Preferred Writer <br />
                    </label>
                    <div class="col-sm-9">
                      <v-select
                        v-model="orderForm.writer_id"
                        @input="calculatePrice"
                        placeholder="Start typing to search"
                        :options="writers"
                        :reduce="(writer) => writer.id"
                        label="first_name"
                      >
                        <template slot="option" slot-scope="option">
                          {{ option.first_name }}
                          <p class="is-rating-stars-1 d-flex mt-1 ml-1">
                            <span
                              v-for="(i, index) in 5"
                              :key="index + 'rating'"
                            >
                              <span
                                class="text-warning icon mt-1"
                                :class="
                                  option.rating.average_rating >= index + 1
                                    ? 'icon-star-full2'
                                    : 'icon-star-empty3'
                                "
                              ></span>
                            </span>
                            <span
                              style="
                                margin-left: 10px;
                                margin-top: 4px;
                                font-weight: bold;
                              "
                              >{{ option.rating.average_rating || "0" }}
                            </span>
                          </p>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.first_name }}
                          <p class="is-rating-stars-1 d-flex mt-1 ml-1">
                            <span
                              v-for="(i, index) in 5"
                              :key="index + 'rating'"
                            >
                              <span
                                class="text-warning icon mt-1"
                                :class="
                                  option.rating.average_rating >= index + 1
                                    ? 'icon-star-full2'
                                    : 'icon-star-empty3'
                                "
                              ></span>
                            </span>
                            <span
                              style="
                                margin-left: 10px;
                                margin-top: 4px;
                                font-weight: bold;
                              "
                              >{{ option.rating.average_rating || "0" }}
                            </span>
                          </p>
                        </template>
                      </v-select>
                    </div>
                    <!-- {{writers}} -->
                    <!-- <div class="col-sm-2">
                        <button
                              class="btn btn-dark btn-icon"
                              type="button"
                              @click="orderForm.writer_id =  ''"
                            >
                              Remove Writer
                            </button>
                      </div> -->
                  </div>
                  <div class="form-group row" v-else>
                    <label
                      for="inputEmail3"
                      class="col-sm-3 col-form-label py-0 text-right"
                      >Writer Id</label
                    >
                    <div class="col-sm-9">
                      <input
                        type="numeric"
                        v-model="orderForm.writer_id"
                        class="form-control"
                        id="inputEmail3"
                        @input="calculatePrice"
                      />
                      <p class="my-1 text-left">
                        <span class="icon-help"></span> Enter your favourite
                        writer ID if you've had previous writers.
                      </p>
                    </div>
                  </div>

                  <!-- ends prefered writer -->
                  <!-- totals and next -->
                  <div class="form-group row">
                    <!-- <label
                      for="inputEmail3"
                      class="
                        col-2 col-sm-3 col-form-label py-0
                        text-left text-sm-right
                        font-weight-bold
                      "
                      >Total price:</label
                    >
                    <div class="col-5 col-sm-3">
                      <h1 class="font-weight-bold text-left">${{ "0.0" }}</h1>
                    </div> -->
                    <div class="col-12 col-sm-6 offset-sm-3 text-left">
                      <button
                        class="
                          btn btn--custom btn-block
                          btn-lg
                          px-5
                          py-2
                          font-weight-bold
                          nav-link
                        "
                        @click="step = 3"
                      >
                        NEXT STEP
                      </button>
                    </div>
                  </div>
                  <!-- ends totals and next -->
                </div>
              </div>
              <!-- ends step 2 -->
              <div
                class="tab-pane fade"
                :class="step == 3 ? 'show active' : ''"
                id="account"
                role="tabpanel"
                aria-labelledby="account-tab"
              >
                <!-- account -->
                <div class="box--shadow bg-white rounded-custom   ">
                  <div
                    v-if="isAuthenticated && Object.keys(authUser).length > 0"
                  >
                    <div class="py-2">
                      <h5 class="my-0">
                        You are logged in as {{ authUser.email || "" }}
                      </h5>
                    </div>
                    <div class="px-3 py-2">
                      <div
                        class="rounded mb-1 py-1 bg-danger-300"
                        v-if="pages_message"
                      >
                        {{ pages_message || "" }}
                      </div>
                      <button
                        type="button"
                        @click="doPlaceOrder('checkout')"
                        class="btn btn-block btn--custom text-uppercase rounded"
                        :disabled="is_loading"
                      >
                        {{
                          is_loading ? "Loading.." : "Proceed to safe checkout"
                        }}
                      </button>
                    </div>
                  </div>
                  <div v-else class="is-account">
                    <ul
                      class="
                        nav nav-pills
                        mb-3
                        nav-justified
                        border-1 border-primary
                        rounded
                        shadow
                      "
                    >
                      <li class="nav-item">
                        <a
                          href="#bottom-divided-tab1"
                          class="nav-link active show"
                          data-toggle="tab"
                          @click="customer_type = 'new'"
                          >New Customer</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="#bottom-divided-tab2"
                          class="nav-link"
                          data-toggle="tab"
                          @click="customer_type = 'returning'"
                          >Returning Customer</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content py-2 px-3">
                      <!-- register tab -->
                      <div
                        class="tab-pane fade active show"
                        id="bottom-divided-tab1"
                      >
                        <form
                          class="flex-fill"
                          ref="registerForm"
                          @submit.prevent="doPlaceOrder('register')"
                        >
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >First & Last Names
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="registerForm.first_name"
                                      name="first_name"
                                      required
                                      type="text"
                                      class="form-control"
                                      placeholder="First name"
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-check text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="registerForm.last_name"
                                      required
                                      name="last_name"
                                      type="text"
                                      class="form-control"
                                      placeholder="Second name"
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-check text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <input
                                v-model="registerForm.email"
                                name="email"
                                type="email"
                                class="form-control"
                                placeholder="Email Address"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Country <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <v-select
                                required
                                label="name"
                                v-model="registerForm.country"
                                placeholder="Start typing to search"
                                :options="countryNames"
                                :reduce="(country) => country"
                              >
                              </v-select>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Phone
                            </label>
                            <div class="col-sm-9 d-flex flex-row">
                              <div
                                class="
                                  country-flag
                                  rounded
                                  border-1 border-muted
                                  text-center
                                  px-2
                                  mr-2
                                  d-flex
                                  flex-row
                                  align-items-center align-content-center
                                  justify-content-center
                                "
                                style="width: 120px !important"
                              >
                                <span style="font-size: 22px">{{
                                  registerForm.country.emoji || ""
                                }}</span>
                                <p class="my-0 ml-1">
                                  + {{ registerForm.country.phone || "" }}
                                </p>
                              </div>
                              <input
                                v-model="registerForm.phone"
                                name="phone"
                                type="tel"
                                class="form-control"
                                placeholder="Your Phone"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Password
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="registerForm.password"
                                      name="password"
                                      type="password"
                                      class="form-control"
                                      placeholder="Create password"
                                      required
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-lock text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div
                                    class="
                                      form-group
                                      form-group-feedback
                                      form-group-feedback-right
                                    "
                                  >
                                    <input
                                      v-model="
                                        registerForm.password_confirmation
                                      "
                                      name="password_confirmation"
                                      type="password"
                                      class="form-control"
                                      placeholder="Repeat password"
                                      required
                                    />
                                    <div class="form-control-feedback">
                                      <i class="icon-user-lock text-muted"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- ends pages slides and PowerPoint -->
                          <!-- totals and next -->
                          <div class="form-group row">
                            <!-- <label
                              for="inputEmail3"
                              class="
                                col-2 col-sm-3 col-form-label py-0
                                text-left text-sm-right
                                font-weight-bold
                              "
                              >Total price:</label
                            >
                            <div class="col-5 col-sm-3">
                              <h1 class="font-weight-bold text-left">
                                ${{ "0.0" }}
                              </h1>
                            </div> -->
                            <div class="col-12 col-sm-6 offset-sm-3 text-left">
                              <button
                                class="
                                  btn btn--custom btn-block
                                  btn-lg
                                  px-5
                                  py-2
                                  font-weight-bold
                                  text-uppercase
                                  nav-link
                                "
                                type="submit"
                                :disabled="is_loading"
                              >
                                {{
                                  is_loading
                                    ? "Loading.."
                                    : "Proceed to safe checkout"
                                }}
                              </button>
                            </div>
                          </div>
                          <!-- ends totals and next -->
                        </form>
                      </div>
                      <!-- /register tab -->
                      <!-- login tab -->
                      <div class="tab-pane fade" id="bottom-divided-tab2">
                        <form
                          class="is-login"
                          ref="loginForm"
                          @submit.prevent="doPlaceOrder('login')"
                        >
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <input
                                type="email"
                                v-model="loginForm.email"
                                class="form-control"
                                id="inputEmail3"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputEmail3"
                              class="col-sm-3 col-form-label py-0 text-right"
                              >Password
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                              <input
                                type="password"
                                v-model="loginForm.password"
                                class="form-control"
                                id="inputEmail3"
                                required
                              />
                            </div>
                          </div>
                          <!-- ends pages slides and PowerPoint -->
                          <!-- totals and next -->
                          <div class="form-group row">
                            <div class="col-12 col-sm-6 text-left offset-sm-3">
                              <button
                                class="
                                  btn btn--custom btn-block rounded
                                  btn-lg
                                  px-5
                                  py-2
                                  font-weight-bold
                                  nav-link
                                  text-uppercase
                                "
                                type="submit"
                                :disabled="is_loading"
                              >
                                {{
                                  is_loading
                                    ? "Loading.."
                                    : "Proceed to safe checkout"
                                }}
                              </button>
                            </div>
                          </div>
                          <!-- ends totals and next -->
                        </form>
                      </div>
                      <!-- /login tab -->
                    </div>
                  </div>
                </div>
                <!-- ends account -->
              </div>
              <!-- ends step 3 -->
            </div>
          </div>


          <div class="col-12 col-sm-4 text-right">
            <div class="card px-0 pb-4  rounded totals-summary">
              <div class="card-body">
                <h4 class="summary__title text-left border-bottom  ">Summary</h4>

                <h6 class="order-topic text-left">
                  <strong>Title: </strong> {{ orderForm.title }}
                </h6>
                <!-- <table
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Academic level:</strong> {{ academic_level }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Subject area: </strong>{{ subject_area }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Type of paper:</strong> {{ document_type }}
                    </td>
                  </tr>
                </table> -->

                <table
                  v-if="orderForm_type == 'Custom Papers'"
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Academic level:</strong> {{ academic_level }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Subject area: </strong>{{ subject_area }}
                    </td>
                  </tr>
                  <tr>
                    <td class="order-short-info mb-0 pb-1">
                      <strong>Type of paper:</strong> {{ document_type }}
                    </td>
                  </tr>
                </table>
                <table
                  v-else
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr>
                    <td v-if="orderForm.description_extras && orderForm.description_extras.course_code" class="order-short-info mb-0 pb-1">
                      <strong>Course Code:</strong> {{orderForm.description_extras.course_code}}
                    </td>
                  </tr>
                  <tr>
                    <td v-if="orderForm.description_extras && orderForm.description_extras.course_name"  class="order-short-info mb-0 pb-1">
                      <strong>Course Name: </strong>{{orderForm.description_extras.course_name}}
                    </td>
                  </tr>
                  <tr>
                    <td v-if="orderForm.description_extras && orderForm.description_extras.university" class="order-short-info mb-0 pb-1">
                      <strong>University:</strong> {{orderForm.description_extras.university}}
                    </td>
                  </tr>
                  <tr>
                    <td  class="order-short-info mb-0 pb-1">
                      <strong>Delivery Time:</strong> {{orderForm.special_urgency}} Days
                    </td>
                  </tr>
                </table>


                <hr class="mt-0" />
                <table
                  class="borderless"
                  style="min-width: 100%; text-align: left !important"
                >
                  <tr
                    v-for="(price, index) in getPrice"
                    :key="index + 'test'"
                    :class="price.title == 'Total' ? 'total-label' : ''"
                  >
                    <td>{{ price.title || "" }}</td>
                    <td class="text-right text-black font-weight-bold price">
                      ${{ price.value || "" }}
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div
                        v-if="message"
                        class="alert my-1 rounded"
                        :class="message.type ? 'alert-success' : 'alert-danger'"
                      >
                        <span v-html="message.message || ''"></span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="card-footer px-3 pb-2">
                <div class="form-group mb-2 text-left">
                  <label for="inputEmail3" class="text-left">Coupon Code</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter coupon code for discounts"
                      v-model="orderForm.coupon_code"
                      class="form-control"
                      id="inputEmail3"
                      @input="calculatePrice"
                    />
                    <div
                      v-if="message"
                      class="alert my-1 rounded"
                      :class="message.type ? 'alert-success' : 'alert-danger'"
                    >
                      <span v-html="message.message || ''"></span>
                    </div>
                    <div v-if="coupon" class="alert alert-success rounded my-1">
                      <span v-html="coupon || ''"></span>
                    </div>
                  </div>
                </div>
                <div
                  class="rounded mb-1 py-1 bg-danger-300"
                  v-if="pages_message"
                >
                  {{ pages_message || "" }}
                </div>

                <button
                  v-if="step == 1 || step == 2"
                  type="button"
                  @click="nextStep(step + 1)"
                  class="btn btn-block btn--custom  "
                >
                  Next Step
                </button>
                <button
                  v-else
                  type="button"
                  @click="doPlaceOrder('checkout')"
                  class="
                    btn btn-block btn--custom
                    text-uppercase
                  "
                  :disabled="is_loading"
                >
                  {{ is_loading ? "Loading.." : "Proceed to safe checkout" }}
                </button>
              </div>
              <!-- <div class="text-center">
                <img :src="mcafee" width="100px" alt="mcafee secure" />
              </div> -->
              <div class="paymentsBy mx-3">
                <div class="stripeHead">
                  <p>Secure Payment </p>
                </div>
                <img src="/orders/allPayments.png" alt="Payment Methods">
              </div>

              <div class="securities mx-5 ">
                <img class="" src="/orders/dmcaGroup.png" alt="DMCA">
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { countries } from "countries-list";
import { mapGetters, mapActions, mapState } from "vuex";
import BasePopover from "../_base-popover.vue";
import FileUploader from "../formcontrols/FileUploader.vue";
export default {
  name: "PlaceOrder",
  components: { BasePopover, FileUploader },
  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country]);
    },
    ...mapGetters("setups", [
      "academic_levels",
      "document_types",
      "language_styles",
      "number_of_pages",
      "spacing",
      "styles",
      "subject_areas",
      "urgency",
      "writer_levels",
      "additional_services",
      "defaults",
    ]),
    ...mapGetters("auth", ["isAuthenticated", "authUser"]),
    ...mapGetters("order", ["getPrice"]),
    ...mapState("order", ["message", "coupon"]),
    ...mapState("writers", ["writers"]),
    // ...mapState("setups", ["countries"]),
    no_of_words() {
      return (
        275 *
          this.orderForm.pages *
          this.spacing.find((x) => x.id == this.orderForm.spacing).factor || 1
      );
    },
    subject_area() {
      return (
        this.subject_areas.find((x) => x.id == this.orderForm.subject_area)
          .desc || ""
      );
    },

    document_type() {
      return (
        this.document_types.find((x) => x.id == this.orderForm.document_type)
          .desc || ""
      );
    },
    academic_level() {
      return (
        this.academic_levels.find((x) => x.id == this.orderForm.academic_level)
          .desc || ""
      );
    },

    pages_message() {
      let message = "";
      if (
        this.orderForm.pages < 1 &&
        this.orderForm.charts < 1 &&
        this.orderForm.slides < 1
      ) {
        message = "You need to order at least 1 page, 1 slide or 1 chart";
      } else message = "";
      return message;
    },
  },
  data() {
    return {
      ordertypeTitle:'',
      step: 1,
      orderForm_type:'Custom Papers',
      countries,
      customer_type: "new",
      is_loading: false,
      orderForm: new this.$Form({
        academic_level: "",
        subject_area: "",
        language_style: "",
        urgency: "",
        type: "order",
        document_type: "",
        spacing: "",
        writer_level: "",
        special_urgency:5,
        style: 1,
        additional_services: [],
        pages: this.$route.query && this.$route.query.pages_calc ? this.$route.query.pages_calc :  1,
        no_sources: 0,
        charts: 0,
        slides: 0,
        title: "",
        description: "",
        state: "",
        speciality: "",
        files: [],
        writer_id: "",
        coupon_code: "",
        description_extras:{
          type:'',
          course_code:'',
          course_name:'',
          university:'',
          school_password:'',
          school_email_username:"",
          comments:'',

        },
      }),
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
      }),
      loginForm: new this.$Form({
        email: "",
        password: "",
      }),
      orderFormTypes:[
        {
          title:'Academic writing',
          desc:'Custom Papers',
          type:'order',
        },
        {
          title:'Exam',
          desc:'Custom Exams',
          type:'exam',
        },
        {
          title:'Quiz',
          desc:'Custom Quiz',
          type:'quiz',
        },
        {
          title:'Shadow Health',
          desc:'Shadow Health',
          type:'shadow_soap',

        },
        {
          title:'iHuman ',
          desc:'Custom iHuman',
          type:'ihuman',

        },
        // {
        //   title:'Full Class',
        //   desc:'Custom Classes',
        //   type:'class',
        // },
      ],
      ShadowHealthTypes:[
       
       {
         title:'Shadow Health',
         desc:'Shadow Health with SOAP Note',
         type:'shadow_soap',
       },
       {
         title:'Shadow Health',
         desc:'Shadow Health Only',
         type:'shadow'
       },
       // {
       //   title:'Shadow Health',
       //   desc:'SOAP Note Only',
       //   type:'shadow_soapnote_only',
       // }, 
       
       
     ],
      errors: {
        title: "",
        description: "",
      },
    };
  },
  async mounted() {
    this.$store.state.loading = true;
    await this._getSetups();
    await this._getCoupon();
    await this.getWriters();
    this.$store.state.loading = false;
  },
  methods: {
    checkIfEmailInString(text) {
      var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      return re.test(text);
    },
    // nextStep(step) {
    //   // validate title and description before going to the next step
    //   let canGoNext = true;

    //   if (this.orderForm.title == "" || this.orderForm.title == null) {
    //     this.errors.title = "Title field is required";
    //     canGoNext = true;
    //   } else if (this.orderForm.title.length < 8) {
    //     this.errors.title =
    //       "The title field should be greater than 7 characters";
    //     canGoNext = true;
    //   } else if (this.checkIfEmailInString(this.orderForm.title)) {
    //     this.errors.title = "The title field should not contain email address";
    //     canGoNext = true;
    //   } else {
    //     this.errors.title = "";
    //   }
    //   if (this.checkIfEmailInString(this.orderForm.description)) {
    //     this.errors.description =
    //       "The description field should not contain email address";
    //     canGoNext = true;
    //   } else if (
    //     this.orderForm.description == "" ||
    //     this.orderForm.description == null
    //   ) {
    //     this.errors.description = "The description field is required";
    //     canGoNext = true;
    //   } else {
    //     this.errors.description = "";
    //   }
    //   canGoNext = true;
    //   if (canGoNext && (step == 1 || step == 2 || step == 3)) {
    //     this.step = step;
    //   }
    // },

    nextStep(step) {
      // validate title and description before going to the next step
      let canGoNext = true;

      if(this.orderForm.type == 'order'){

        if (this.orderForm.title == "" || this.orderForm.title == null) {
          this.errors.title = "Title field is required";
          canGoNext = false;
        } else if (this.orderForm.title.length < 8) {
          this.errors.title =
            "The title field should be greater than 7 characters";
          canGoNext = false;
        } else if (this.checkIfEmailInString(this.orderForm.title)) {
          this.errors.title = "The title field should not contain email address";
          canGoNext = false;
        } else {
          this.errors.title = "";
        }
        if (this.checkIfEmailInString(this.orderForm.description)) {
          this.errors.description =
            "The description field should not contain email address";
          canGoNext = false;
        } else if (
          this.orderForm.description == "" ||
          this.orderForm.description == null
        ) {
          this.errors.description = "The description field is required";
          canGoNext = false;
        } else {
          this.errors.description = "";
        }
        if (canGoNext && (step == 1 || step == 2 || step == 3)) {
          this.step = step;
        }
      }else{
        canGoNext = true;
        if (canGoNext && (step == 1 || step == 2 || step == 3)) {
          this.step = step;
        }
      }
    },

    activateShadowHealth(e){
      console.log("this is event of activateShadowHealth===>", e)
      // this.orderForm_type = e.desc;
      this.ordertypeTitle = e.desc;

      this.orderForm.type = e.type;
      this.orderForm.description_extras.type = e.type;
      this.calculatePrice();


    },


    changeOrderTypeForm(e){
      console.log("this is event of changeOrderTypeForm===>", e)
      this.orderForm_type = e.desc;
      this.ordertypeTitle = e.desc;
      this.orderForm.type = e.type;
      this.calculatePrice();

      if (this.orderForm.type == 'quiz' ) {
        // console.log("this is quiz")
        this.orderForm.description_extras.type = e.title
        console.log("this is quiz", this.orderForm.description_extras.type)

      } else {
        if (this.orderForm.type == 'ihuman' ) {
        // console.log("this is quiz")
          this.orderForm.description_extras.type = e.title
          console.log("this is ihuman", this.orderForm.description_extras.type)

        } else {

          if (this.orderForm.type == 'class' ) {
          // console.log("this is quiz")
            this.orderForm.description_extras.type = e.title
            console.log("this is class", this.orderForm.description_extras.type)

          } else {
            if (this.orderForm.type == 'order' ) {
            // console.log("this is quiz")
              this.orderForm.description_extras.type = e.title
              console.log("this is order", this.orderForm.description_extras.type)

            } else {
              if (this.orderForm.type == 'shadow' ) {
              // console.log("this is quiz")
                this.orderForm.type = 'shadow_soap';
                this.orderForm.description_extras.type = 'shadow_soap'
                console.log("this is order", this.orderForm.description_extras.type)

              } else {
                
              }
              
            }
            
          }
          
        }
      }

    },

    ...mapActions("setups", ["getSetups", "getCountries"]),
    ...mapActions("auth", ["doLogin", "doRegister"]),
    ...mapActions("order", ["_calculatePrice", "_placeOrder", "_getCoupon"]),
    ...mapActions("writers", ["getWriters"]),
    _getSetups() {
      this.getSetups()
        .then(() => {
          this.orderForm.subject_area = this.defaults?.def_subject_area || "";
          this.orderForm.urgency = this.defaults?.def_urgency || "";
          this.orderForm.document_type = this.defaults?.def_doc_type || "";
          this.orderForm.academic_level =
            this.defaults?.def_academic_level || "";
          this.orderForm.language_style = this.language_styles[0]?.id;
          this.orderForm.spacing = this.defaults?.def_spacing || "";
          this.orderForm.writer_level = this.defaults?.def_writer_level || "";
          this.orderForm.style = this.defaults?.def_style || "";
          this.calculatePrice();
        })
        .catch((err) => {
          console.log("getsetups", err);
          this.$router.push({ name: "503" });
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },

    activateAcademicLevel(level) {
      this.orderForm.academic_level = level.id;
      this.calculatePrice();
    },
    activateAcademicStyle(style) {
      this.orderForm.style = style.id;
    },
    activateLanguageStyle(style) {
      this.orderForm.language_style = style.id;
    },
    activateSpacing(space) {
      this.orderForm.spacing = space.id;
      this.calculatePrice();
    },
    activateWriterPref(pref) {
      this.orderForm.writer_level = pref.id;
      this.calculatePrice();
    },
    updatePages(option) {
      if (!option) {
        if (this.orderForm.pages > 0) {
          this.orderForm.pages--;
        }
      } else {
        this.orderForm.pages++;
        // if (this.orderForm.pages <= this.number_of_pages.max_pages) {
        // this.orderForm.pages++;
        // }
      }
      console.log("this.orderForm.pages", this.orderForm.pages);
      this.calculatePrice();
    },
    decrementSources() {
      if (this.orderForm.no_sources > 0) {
        this.orderForm.no_sources--;
      }
    },
    updateCharts(option) {
      if (!option) {
        if (this.orderForm.charts > 0) {
          this.orderForm.charts--;
        }
      } else {
        this.orderForm.charts++;
      }
      this.calculatePrice();
    },
    updateSlides(option) {
      if (!option) {
        if (this.orderForm.slides > 0) {
          this.orderForm.slides--;
        }
      } else {
        this.orderForm.slides++;
      }
      this.calculatePrice();
    },

    calculatePrice() {
      console.log("this.orderForm", this.orderForm);
      this.is_loading = true;
      this._calculatePrice(this.orderForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    uploadFiles(event) {
      let files = event.target.files;

      Object.keys(files).forEach((file) => {
        this.orderForm.files.push(files[file]);
      });
    },

    deleteFile(fileToDelete) {
      let files = this.orderForm.files.filter(
        (file) => file.name !== fileToDelete.name
      );
      this.orderForm.files = files;
    },

    async doPlaceOrder(action) {
      console.log("action", action);
      console.log("customer_type", this.customer_type);

      // if is logged in place order
      this.is_loading = true;
      if (this.isAuthenticated && Object.keys(this.authUser).length > 0) {
        // place order
        this._placeOrder(this.orderForm)
          .then((res) => {
            if (res.status === 200) {
              this.$router.push(
                `/${process.env.VUE_APP_INSTALL_DIR}/home/order/${res.data.Data.order_id}`
              );
              // alert('Success')
              this.loading = false;
            } else {
              this.$notify({
                title: "Error",
                text: res.data
                  ? res.data.Message
                  : "Error while placing order, please try again",
                style: "danger",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.is_loading = false;
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          })
          .finally(() => (this.loading = false));
      } else {
        if (this.customer_type === "new") {
          // register new customer
          this.loginForm.email = this.registerForm.email;
          this.loginForm.password = this.registerForm.password;

          this.registerForm.phone = `${
            this.registerForm.country.phone + this.registerForm.phone
          }`;
          this.registerForm.country = this.registerForm.country.name;
          await this.doRegister(this.registerForm)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              this.is_loading = false;
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data ? err.response.data.Message : "Error",
                style: "danger",
              });
            });
        }
        // login customer

        await this.doLogin(this.loginForm)
          .then((res) => {
            console.log("login res", res);
            localStorage.setItem(
              "token",
              `Bearer ${res.data.Data.access_token}`
            );
          })
          .catch((err) => {
            console.log("loggin err", err);
            this.is_loading = false;
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          });

        axios.defaults.headers.common = {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: localStorage.getItem("token"),
        };

        // place order
        console.log("token", localStorage.getItem("token"));
        await this._placeOrder(this.orderForm)
          .then((res) => {
            document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/home/order/${res.data.Data.order_id}`;
            // this.$router.push(`/${process.env.VUE_APP_INSTALL_DIR}/home/order/${res.data.Data.order_id}`);
          })
          .catch((err) => {
            this.is_loading = false;
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });

            console.log("order err", err);
          });
        this.is_loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>